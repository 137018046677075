import React from "react"
import { graphql, navigate } from "gatsby"
import Textos from "../components/Textos/Textos"
import Boton from "../components/Botones/Boton"
import Video from "../components/Videos/Video"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Grid } from "@mui/material"
import PieDePagina from "../components/PieDePagina"
import TextoConLink from "../components/Textos/TextoConLink"
import PasadorImagenes from "../components/Imagenes/PasadorImagenes"
import GaleriaImagenes from "../components/Imagenes/GaleriaImagenes"
import PasadorImagenesLink from "../components/Imagenes/PasadorImagenesLink"
import ReactMarkdown from "react-markdown"

const IndexPage = ({ data }) => {
  const footer = data.strapiPrincipal.footer

  return (
    <Layout>
      <Seo title={data.strapiPrincipal.titulo} />
      <Grid container>
        {data.strapiPrincipal.dinamica.map(item => (
          <Grid
            container
            item
            xs={item.breakpoints.xs === 0 ? "auto" : item.breakpoints.xs}
            sm={item.breakpoints.sm === 0 ? "auto" : item.breakpoints.sm}
            md={item.breakpoints.md === 0 ? "auto" : item.breakpoints.md}
            lg={item.breakpoints.lg === 0 ? "auto" : item.breakpoints.lg}
            xl={item.breakpoints.xl === 0 ? "auto" : item.breakpoints.xl}
            key={`zona-${item.__typename}${item.id}`}
            sx={{
              display: {
                xs: `${item.display.xs}`,
                sm: `${item.display.sm}`,
                md: `${item.display.md}`,
                lg: `${item.display.lg}`,
                xl: `${item.display.xl}`,
              },
            }}
          >
            {(() => {
              switch (item.__typename) {
                case "StrapiComponentBotonesBoton":
                  return <Boton item={item} />
                case "StrapiComponentVideosIframe":
                  return (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        position: "relative",
                        height: 0,
                        paddingBottom: "56.25%",
                      }}
                    >
                      <iframe
                        src={item.link}
                        title="Video"
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      ></iframe>
                    </Grid>
                  )

                case "StrapiComponentTextosTexto":
                  return <Textos item={item} />
                case "StrapiComponentTextosTextoEnriquecido":
                  return (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        color: `${item.propiedades_texto.color_letra}`,
                        // backgroundColor: `${item.color_fondo}`,
                        textAlign: item.propiedades_texto.alineacion,
                        fontStyle: `${item.propiedades_texto.font_style}`,
                        fontFamily: `${
                          item.propiedades_texto.tipografia === "roboto_slab"
                            ? "Roboto Slab"
                            : item.propiedades_texto.tipografia ===
                              "roboto_mono"
                            ? "Roboto Mono"
                            : item.propiedades_texto.tipografia === "bebas_neue"
                            ? "Bebas Neue"
                            : item.propiedades_texto.tipografia
                        }`,
                        fontWeight: `${item.propiedades_texto.font_weight}`,
                        padding: `${item.propiedades_texto.padding}px`,
                      }}
                    >
                      <ReactMarkdown>{item.texto_enriquecido}</ReactMarkdown>
                    </Grid>
                  )
                case "StrapiComponentUtilesVacio":
                  return (
                    <Grid item sx={{ width: "100%" }}>
                      <p>
                        <br />
                      </p>
                    </Grid>
                  )
                case "StrapiComponentUtilesSeccion":
                  return <div id={item.nombre} />
                case "StrapiComponentVideosVideo":
                  return <Video item={item} />
                case "StrapiComponentImagenesGaleriaImagenes":
                  return <GaleriaImagenes item={item} />
                case "StrapiComponentTextosTextoConLink":
                  return <TextoConLink item={item} />
                case "StrapiComponentImagenesPasadorImagenes":
                  return <PasadorImagenes item={item} />
                case "StrapiComponentImagenesPasadorImagenesLink":
                  return <PasadorImagenesLink item={item} />
                default:
                  return item.__typename
              }
            })()}
          </Grid>
        ))}
      </Grid>

      {/* FOOTER */}
      <PieDePagina footer={footer} />
      <div
        style={{
          position: "fixed",
          right: 10,
          bottom: 10,
          // z-index:1000,
          width: 100,
          height: 100,
          textAlign: "center",
          marginTop: 22,
        }}
        onClick={() => navigate("/como-ayudar")}
      >
        <StaticImage
          style={{ cursor: "pointer" }}
          src="../images/donar.png"
          alt="Donar"
          placeholder="blurred"
          layout="fixed"
          width={100}
          height={100}
        />
      </div>

      {/* TODO booleano si mostrar en esta pagina o nom ademas de las propiedades de margin etc width */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    strapiPrincipal {
      imagenFondo {
        alternativeText
        file {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      titulo
      footer {
        ... on StrapiComponentTextosTextoConLink {
          id
          __typename
          destinoLink
          link
          texto
          # tipo
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentRedesLinkedin {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesEmail {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesTelefono {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesWhatsapp {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesTwitter {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesInstagram {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesFacebook {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentUtilesPropiedadesFooter {
          __typename
          id
          color_fondo
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentUtilesVacio {
          __typename
          id
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentImagenesPasadorImagenes {
          id
          __typename
          padding
          temporizador
          imagenes {
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
            alternativeText
          }
          display {
            md
            lg
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentBotonesBoton {
          __typename
          id
          # size
          destino
          link
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
          color_fondo
          texto
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
        }
        ... on StrapiComponentTextosTexto {
          __typename
          id
          texto
          tipo
          color_fondo
          gutter_bottom
          propiedades_texto {
            font_style
            font_weight
            color_letra
            alineacion
            tipografia
            padding
          }
          breakpoints {
            xs
            sm
            md
            lg
            xl
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
      }
      dinamica {
        ... on StrapiComponentTextosTextoEnriquecido {
          __typename
          id
          texto_enriquecido
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentImagenesPasadorImagenesLink {
          links {
            link
          }
          id
          __typename
          padding
          temporizador
          imagenes {
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
            alternativeText
          }
          display {
            md
            lg
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentVideosIframe {
          __typename
          id
          link
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentUtilesSeccion {
          __typename
          id
          nombre
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentTextosTextoConLink {
          id
          __typename
          destinoLink
          link
          texto
          # tipo
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentUtilesVacio {
          __typename
          id
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }

        ... on StrapiComponentImagenesGaleriaImagenes {
          __typename
          padding_imagen
          padding
          ampliar
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          imagenes {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
              id
            }
          }
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints_imagen {
            lg
            md
            sm
            xl
            xs
          }
          id
        }
        ... on StrapiComponentImagenesPasadorImagenes {
          id
          __typename
          padding
          temporizador
          imagenes {
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
            alternativeText
          }
          display {
            md
            lg
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }

        ... on StrapiComponentVideosVideo {
          __typename
          id
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
          video {
            alternativeText
            mime
            file {
              publicURL
            }
          }
        }

        ... on StrapiComponentBotonesBoton {
          __typename
          id
          # size
          destino
          link
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
          color_fondo
          texto
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
        }
        ... on StrapiComponentTextosTexto {
          __typename
          id
          texto
          tipo
          color_fondo
          gutter_bottom
          propiedades_texto {
            font_style
            font_weight
            color_letra
            alineacion
            tipografia
            padding
          }
          breakpoints {
            xs
            sm
            md
            lg
            xl
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
      }
    }
  }
`
